import React from "react"
import Layout from "../components/layout/layout"
import styled from 'styled-components'
import { Helmet } from "react-helmet"

const MessageSection = styled.section`
    min-height: calc(100vh - 139px);
    
    @media only screen and (min-width: 900px) {
        min-height: calc(100vh - 175px)
    }
`;

const MessageContainer = styled.div`
    width: 80%;
    max-width: 820px;
    margin: auto;
    padding: 5px 0;
`;

const MessageHeading = styled.h1`
    font-size: var(--type-xxxl);
    color: var(--accent-turquoise-500);
    margin-bottom: 0;
    text-transform: none;
`;

const Message = styled.p`
    color: var(--neutral-900);
`;

export default function PageNotFound() {
    return (
        <Layout>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>Page Not Found | Caplin Solar</title>
                <html lang="en"></html>
            </Helmet>
            <MessageSection>
                <MessageContainer>
                    <MessageHeading>Page not found</MessageHeading>
                    <Message>Sorry, the page you were looking for could not be found. Try again, or use the navigation above to locate the page.</Message>
                </MessageContainer>
            </MessageSection>    
        </Layout>
    )
}